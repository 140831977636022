module Main exposing (main)

import Browser exposing (Document, UrlRequest)
import Browser.Navigation as Nav
import Html
import Html.Attributes as Attr
import Html.Events as Event
import Http
import Interface exposing (sendToStorage, StorageItem)
import Json.Decode as D
import Json.Encode as E
import Page.Audio as AP
import Page.AudioTranslations as ATsP
import Page.Audios as AsP
import Page.Doc as DP
import Page.Protocols as PsP
import Page.Segment as SP
import Page.Segments as SsP
import Page.Subtitles as STsP
import Page.Transcriptions as TsP
import Url exposing (Url)
import Url.Parser
    exposing
        ( (</>)
        , Parser
        , map
        , oneOf
        , parse
        , s
        , string
        , top
        )


type alias Model =
    { route : Route
    , page : Page
    , navKey : Nav.Key
    , config : Config
    , authtoken : String
    , email : String
    , password : String
    , isSubmittingForm : Bool
    , error : Maybe Error
    }


type Msg
    = AudioMsg AP.Msg
    | AudiosMsg AsP.Msg
    | AudioTranslationsMsg ATsP.Msg
    | DocMsg DP.Msg
    | LinkClicked UrlRequest
    | ProtocolsMsg PsP.Msg
    | SegmentsMsg SsP.Msg
    | SegmentMsg SP.Msg
    | SignInApiResponded (Result Error Data)
    | SubtitlesMsg STsP.Msg
    | TranscriptionsMsg TsP.Msg
    | UrlChanged Url
    | UserClickedSignOut
    | UserSubmittedForm
    | UserUpdatedInput Field String


type Route
    = Audio String
    | Audios
    | AudioTranslations
    | Doc String
    | Home
    | NotFound
    | Protocols
    | Segment String
    | Segments
    | Subtitles
    | Transcriptions


type Page
    = AudioPage AP.Model
    | AudiosPage AsP.Model
    | AudioTranslationsPage ATsP.Model
    | DocPage DP.Model
    | ErrorPage
    | HomePage
    | LoginPage
    | NotFoundPage
    | ProtocolsPage PsP.Model
    | SegmentPage SP.Model
    | SegmentsPage SsP.Model
    | SubtitlesPage STsP.Model
    | TranscriptionsPage TsP.Model


type alias StorageItem =
    { key : String
    , value : E.Value
    }


type alias Flags =
    { apiurl : String
    , authurl : String
    , anon_apikey : String
    , authtoken : String
    , email : String
    }


type alias Config =
    { apiurl : String
    , authurl : String
    , anon_apikey : String
    }


type Field
    = Email
    | Password


type alias Error =
    { message : String
    , invalid : Bool
    }


type alias Data =
    { authtoken : String
    }


main : Program Flags Model Msg
main =
    Browser.application
        { init = init
        , update = update
        , subscriptions = subscriptions
        , view = view
        , onUrlRequest = LinkClicked
        , onUrlChange = UrlChanged
        }


init : Flags -> Url -> Nav.Key -> ( Model, Cmd Msg )
init flags url navKey =
    let
        model =
            { route = parseUrl url
            , page = NotFoundPage
            , navKey = navKey
            , config =
                Config flags.apiurl flags.authurl flags.anon_apikey
            , authtoken = flags.authtoken
            , email = flags.email
            , password = ""
            , isSubmittingForm = False
            , error = Nothing
            }
    in
    setPage model


setPage : Model -> ( Model, Cmd Msg )
setPage model =
    let
        options =
            { apiurl = model.config.apiurl
            , anon_apikey = model.config.anon_apikey
            , authtoken = model.authtoken
            , identifier = ""
            }
    in
    if model.authtoken == "" then
        ( { model | page = LoginPage }
        , Cmd.none
        )
    else
        case model.route of
            Audio s ->
                let
                    ( pmodel, pcmd ) =
                        AP.init { options | identifier = s }
                in
                ( { model | page = AudioPage pmodel }
                , Cmd.map AudioMsg pcmd
                )

            Audios ->
                let
                    ( pmodel, pcmd ) =
                        AsP.init options
                in
                ( { model | page = AudiosPage pmodel }
                , Cmd.map AudiosMsg pcmd
                )

            AudioTranslations ->
                let
                    ( pmodel, pcmd ) =
                        ATsP.init options
                in
                ( { model | page = AudioTranslationsPage pmodel }
                , Cmd.map AudioTranslationsMsg pcmd
                )

            Doc s ->
                let
                    ( pmodel, pcmd ) =
                        DP.init { options | identifier = s }
                in
                ( { model | page = DocPage pmodel }
                , Cmd.map DocMsg pcmd
                )
    
            Home ->
                ( { model | page = HomePage }
                , Cmd.none
                )

            NotFound ->
                ( { model | page = NotFoundPage }
                , Cmd.none
                )

            Protocols ->
                let
                    ( pmodel, pcmd ) =
                        PsP.init options
                in
                ( { model | page = ProtocolsPage pmodel }
                , Cmd.map ProtocolsMsg pcmd
                )

            Segments ->
                let
                    ( pmodel, pcmd ) =
                        SsP.init options
                in
                ( { model | page = SegmentsPage pmodel }
                , Cmd.map SegmentsMsg pcmd
                )

            Segment s ->
                let
                    ( pmodel, pcmd ) =
                        SP.init { options | identifier = s }
                in
                ( { model | page = SegmentPage pmodel }
                , Cmd.map SegmentMsg pcmd
                )

            Subtitles ->
                let
                    ( pmodel, pcmd ) =
                        STsP.init options
                in
                ( { model | page = SubtitlesPage pmodel }
                , Cmd.map SubtitlesMsg pcmd
                )

            Transcriptions ->
                let
                    ( pmodel, pcmd ) =
                        TsP.init options
                in
                ( { model | page = TranscriptionsPage pmodel }
                , Cmd.map TranscriptionsMsg pcmd
                )


view : Model -> Document Msg
view model =
    { title = "Wao Tededo"
    , body = layoutView model
    }


layoutView : Model -> List (Html.Html Msg)
layoutView model =
    [ Html.header [ Attr.class "container-fluid" ]
        [ viewNav model ]
    , currentView model
    ]


viewMenuItem : ( String, String ) -> Html.Html Msg
viewMenuItem ( link, title ) =
    Html.li []
        [ Html.a [ Attr.href link ]
            [ Html.text title ]
        ]


viewAudioMenu : Html.Html Msg
viewAudioMenu =
    Html.li []
        [ Html.details [ Attr.class "dropdown" ]
            [ Html.summary []
                [ Html.text "Audios" ]
            , Html.ul []
                (List.map viewMenuItem
                    [ ( "/audios", "Audios" )
                    , ( "/subtitles", "Subtitles" )
                    , ( "/audio_translations", "Translations" )
                    , ( "/transcriptions", "Transcriptions" )
                    ]
                )
            ]
        ]


viewNav : Model -> Html.Html Msg
viewNav model =
    if model.authtoken == "" then
        Html.h1 [] [ Html.text "Login" ]

    else
        Html.nav []
            [ viewMenuItem ( "/", "Home" )
                :: viewAudioMenu
                :: List.map viewMenuItem
                    [ ( "/protocols", "Protocols" )
                    , ( "/segments", "Segments" )
                    ]
                |> Html.ul []
            , Html.ul []
                [ Html.li []
                    [ Html.text model.email ]
                , Html.li []
                    [ Html.button
                        [ Event.onClick UserClickedSignOut ]
                        [ Html.text "Sign out" ]
                    ]
                ]
            ]


currentView : Model -> Html.Html Msg
currentView model =
    case model.page of
        AudioPage pmodel ->
            AP.view pmodel |> Html.map AudioMsg

        AudiosPage pmodel ->
            AsP.view pmodel |> Html.map AudiosMsg

        AudioTranslationsPage pmodel ->
            ATsP.view pmodel |> Html.map AudioTranslationsMsg

        DocPage pmodel ->
            DP.view pmodel |> Html.map DocMsg
                
        ErrorPage ->
            Html.div [ Attr.class "section" ]
                [ Html.h1 [ Attr.class "title" ]
                    [ Html.text "Error" ]
                ]

        HomePage ->
            Html.div [ Attr.class "section" ]
                [ Html.h1 [ Attr.class "title" ]
                    [ Html.text "Home" ]
                , Html.p [] [ Html.text "Follow the links above." ]
                ]

        LoginPage ->
            Html.main_ [ Attr.class "section" ]
                [ viewLoginForm model ]

        NotFoundPage ->
            Html.div [ Attr.class "section" ]
                [ Html.h1 [ Attr.class "title" ]
                    [ Html.text "Page Not Found" ]
                ]

        ProtocolsPage pmodel ->
            PsP.view pmodel |> Html.map ProtocolsMsg

        SegmentPage pmodel ->
            SP.view pmodel |> Html.map SegmentMsg

        SegmentsPage pmodel ->
            SsP.view pmodel |> Html.map SegmentsMsg

        SubtitlesPage pmodel ->
            STsP.view pmodel |> Html.map SubtitlesMsg

        TranscriptionsPage pmodel ->
            TsP.view pmodel |> Html.map TranscriptionsMsg


viewLoginForm : Model -> Html.Html Msg
viewLoginForm model =
    let
        invalid =
            case model.error of
                Nothing ->
                    False

                Just error ->
                    error.invalid
    in
    Html.form [ Event.onSubmit UserSubmittedForm ]
        [ viewFormInput
            { field = Email
            , value = model.email
            , isInvalid = invalid
            }
        , viewFormInput
            { field = Password
            , value = model.password
            , isInvalid = invalid
            }
        , viewFormControls model
        ]


viewFormInput :
    { field : Field
    , value : String
    , isInvalid : Bool
    }
    -> Html.Html Msg
viewFormInput options =
    let
        invalid =
            if options.isInvalid then
                [ Attr.class "input is-danger" ]

            else
                [ Attr.class "input" ]
    in
    Html.div [ Attr.class "field" ]
        [ Html.label [ Attr.class "label" ]
            [ Html.text (fromFieldToLabel options.field) ]
        , Html.div [ Attr.class "control" ]
            [ Html.input
                ([ Attr.type_ (fromFieldToInputType options.field)
                 , Attr.value options.value
                 , Event.onInput (UserUpdatedInput options.field)
                 ]
                    ++ invalid
                )
                []
            ]
        ]


fromFieldToLabel : Field -> String
fromFieldToLabel field =
    case field of
        Email ->
            "Email"

        Password ->
            "Password"


fromFieldToInputType : Field -> String
fromFieldToInputType field =
    case field of
        Email ->
            "email"

        Password ->
            "password"


viewFormControls : Model -> Html.Html Msg
viewFormControls model =
    let
        busy =
            if model.isSubmittingForm then
                [ Attr.attribute "aria-busy" "true" ]

            else
                []

        error =
            case model.error of
                Nothing ->
                    []

                Just e ->
                    [ Html.p [ Attr.class "help is-danger" ]
                        [ Html.text e.message ]
                    ]
    in
    Html.div [ Attr.class "control" ]
        (Html.button
            [ Attr.disabled model.isSubmittingForm
            , Attr.class "button is-primary"
            ]
            [ Html.text "Sign in" ]
            :: error
        )


subscriptions : Model -> Sub Msg
subscriptions model =
    let
        pageSpecific =
            case model.page of
                DocPage pmodel ->
                    Sub.map
                        DocMsg (DP.subscriptions pmodel)

                _ ->
                    Sub.none
    in
    pageSpecific


update : Msg -> Model -> ( Model, Cmd Msg )
update msg model =
    case msg of
        AudioMsg pmsg ->
            case model.page of
                AudioPage m ->
                    let
                        ( pmodel, pcmd ) =
                            AP.update pmsg m
                    in
                    ( { model | page = AudioPage pmodel }
                    , Cmd.map AudioMsg pcmd
                    )

                _ ->
                    ( { model | page = ErrorPage }, Cmd.none )

        AudiosMsg pmsg ->
            case model.page of
                AudiosPage m ->
                    let
                        ( pmodel, pcmd ) =
                            AsP.update pmsg m
                    in
                    ( { model | page = AudiosPage pmodel }
                    , Cmd.map AudiosMsg pcmd
                    )

                _ ->
                    ( { model | page = ErrorPage }, Cmd.none )

        AudioTranslationsMsg pmsg ->
            case model.page of
                AudioTranslationsPage m ->
                    let
                        ( pmodel, pcmd ) =
                            ATsP.update pmsg m
                    in
                    ( { model | page = AudioTranslationsPage pmodel }
                    , Cmd.map AudioTranslationsMsg pcmd
                    )

                _ ->
                    ( { model | page = ErrorPage }, Cmd.none )
                        
        DocMsg pmsg ->
            case model.page of
                DocPage m ->
                    let
                        ( pmodel, pcmd ) =
                            DP.update pmsg m
                    in
                    ( { model | page = DocPage pmodel }
                    , Cmd.map DocMsg pcmd
                    )

                _ ->
                    ( { model | page = ErrorPage }, Cmd.none )

        LinkClicked urlRequest ->
            case urlRequest of
                Browser.Internal url ->
                    ( model, Nav.pushUrl model.navKey (Url.toString url) )

                Browser.External url ->
                    ( model, Nav.load url )

        ProtocolsMsg pmsg ->
            case model.page of
                ProtocolsPage m ->
                    let
                        ( pmodel, pcmd ) =
                            PsP.update pmsg m
                    in
                    ( { model | page = ProtocolsPage pmodel }
                    , Cmd.map ProtocolsMsg pcmd
                    )

                _ ->
                    ( { model | page = ErrorPage }, Cmd.none )

        SegmentMsg pmsg ->
            case model.page of
                SegmentPage m ->
                    let
                        ( pmodel, pcmd ) =
                            SP.update pmsg m
                    in
                    ( { model | page = SegmentPage pmodel }
                    , Cmd.map SegmentMsg pcmd
                    )

                _ ->
                    ( { model | page = ErrorPage }, Cmd.none )

        SegmentsMsg pmsg ->
            case model.page of
                SegmentsPage m ->
                    let
                        ( pmodel, pcmd ) =
                            SsP.update pmsg m
                    in
                    ( { model | page = SegmentsPage pmodel }
                    , Cmd.map SegmentsMsg pcmd
                    )

                _ ->
                    ( { model | page = ErrorPage }, Cmd.none )

        SubtitlesMsg pmsg ->
            case model.page of
                SubtitlesPage m ->
                    let
                        ( pmodel, pcmd ) =
                            STsP.update pmsg m
                    in
                    ( { model | page = SubtitlesPage pmodel }
                    , Cmd.map SubtitlesMsg pcmd
                    )

                _ ->
                    ( { model | page = ErrorPage }, Cmd.none )

        SignInApiResponded (Ok { authtoken }) ->
            ( { model
                | isSubmittingForm = False
                , authtoken = authtoken
                , page = HomePage
                , password = ""
              }
            , saveLogin authtoken model.email
            )

        SignInApiResponded (Err error) ->
            ( { model
                | isSubmittingForm = False
                , error = Just error
              }
            , Cmd.none
            )

        TranscriptionsMsg pmsg ->
            case model.page of
                TranscriptionsPage m ->
                    let
                        ( pmodel, pcmd ) =
                            TsP.update pmsg m
                    in
                    ( { model | page = TranscriptionsPage pmodel }
                    , Cmd.map TranscriptionsMsg pcmd
                    )

                _ ->
                    ( { model | page = ErrorPage }, Cmd.none )

        UrlChanged url ->
            setPage { model | route = parseUrl url }

        UserClickedSignOut ->
            ( { model | authtoken = "", email = "", page = LoginPage }
            , deleteLogin
            )

        UserSubmittedForm ->
            ( { model
                | isSubmittingForm = True
                , error = Nothing
              }
            , loginPost model
            )

        UserUpdatedInput Email value ->
            ( { model | email = value }
            , Cmd.none
            )

        UserUpdatedInput Password value ->
            ( { model | password = value }
            , Cmd.none
            )


saveLogin : String -> String -> Cmd Msg
saveLogin authtoken email =
    Cmd.batch
        [ sendToStorage "authtoken" (E.string authtoken)
        , sendToStorage "email" (E.string email)
        ]


deleteLogin : Cmd Msg
deleteLogin =
    Cmd.batch
        [ sendToStorage "authtoken" (E.string "")
        , sendToStorage "email" (E.string "")
        ]


loginPost : Model -> Cmd Msg
loginPost model =
    let
        body : E.Value
        body =
            E.object
                [ ( "email", E.string model.email )
                , ( "password", E.string model.password )
                ]
    in
    Http.request
        { method = "POST"
        , headers =
            [ Http.header "apikey" model.config.anon_apikey
            , Http.header "Content-Type" "application/json"
            ]
        , url = model.config.authurl
        , body = Http.jsonBody body
        , expect =
            Http.expectStringResponse
                SignInApiResponded
                handleHttpResponse
        , timeout = Nothing
        , tracker = Nothing
        }


handleHttpResponse : Http.Response String -> Result Error Data
handleHttpResponse response =
    case response of
        Http.BadUrl_ _ ->
            Err
                { message = "Unexpected URL format"
                , invalid = False
                }

        Http.Timeout_ ->
            Err
                { message = "Request timed out, please try again"
                , invalid = False
                }

        Http.NetworkError_ ->
            Err
                { message = "Could not connect, please try again"
                , invalid = False
                }

        Http.BadStatus_ { statusCode } body ->
            let
                invalid =
                    statusCode == 403
            in
            case D.decodeString (errorDecoder invalid) body of
                Ok error ->
                    Err error

                Err _ ->
                    Err
                        { message = "Something unexpected happened"
                        , invalid = False
                        }

        Http.GoodStatus_ _ body ->
            case D.decodeString decoder body of
                Ok data ->
                    Ok data

                Err _ ->
                    Err
                        { message = "Something unexpected happened"
                        , invalid = False
                        }


errorDecoder : Bool -> D.Decoder Error
errorDecoder invalid =
    D.map (\message -> Error message invalid)
        (D.field "message" D.string)


decoder : D.Decoder Data
decoder =
    D.map Data
        (D.field "access_token" D.string)


parseUrl : Url -> Route
parseUrl url =
    case parse matchRoute url of
        Just route ->
            route

        Nothing ->
            NotFound


matchRoute : Parser (Route -> a) a
matchRoute =
    oneOf
        [ map Audio (s "audios" </> string)
        , map Audios (s "audios")
        , map AudioTranslations (s "audio_translations")
        , map Home top
        , map Protocols (s "protocols")
        , map Segment (s "segments" </> string)
        , map Segments (s "segments")
        , map Subtitles (s "subtitles")
        , map Transcriptions (s "transcriptions")
        , map Doc (s "doc" </> string)
        ]
