import { Elm } from '../src/Main.elm';
const org = require('org');

const renderOrg = (data) => {
  // Parse the org data
  var parser = new org.Parser();
  var orgDocument = parser.parse(data);
  var orgHTMLDocument = orgDocument.convert(org.ConverterHTML);
  var htmlString = orgHTMLDocument.toString();

  // Now convert it to a dom object for manipulation
  var domparser = new DOMParser();
  var doc = domparser.parseFromString(htmlString, "text/html");
  var XMLS = new XMLSerializer();

  // Remove the table of contents
  doc.querySelector('ul').remove();

  // Get the title produced by the parser library
  var h = doc.getElementsByTagName('h1')[0];

  // Remove it if nothing was there. Otherwise make it a paragraph.
  if (h.innerHTML == "Untitled") {
    h.remove();
  } else {
    var p = doc.createElement('p');
    p.innerHTML = h.innerHTML;
    h.parentNode.replaceChild(p, h);
  }

  Array.from(doc.querySelectorAll('span.todo')).forEach((todo) => {
    todo.classList.add('tag')
    todo.classList.add('is-warning')
  })

  Array.from(doc.querySelectorAll('span.done')).forEach((done) => {
    done.classList.add('tag')
    done.classList.add('is-success')
  })
                                             
  // If a link is to an image, replace the link with an img
  Array.from(doc.getElementsByTagName('a')).forEach(a => {
    const pattern = /(\.png|\.jpg)$/
    const url = a.getAttribute('href')
    const title = a.innerHTML

    if (url.match(pattern)) {
      const img = doc.createElement('img')

      img.setAttribute('src', url)
      img.setAttribute('title', title)
      a.parentNode.replaceChild(img, a)
    }
  })
      
  return doc.querySelector('body').innerHTML;
}

const getItem = (itemKey) => {
  const itemValue = window.localStorage.getItem(itemKey)

  if (itemValue) {
    return JSON.parse(itemValue);
  } else {
    return '';
  }
}

const getDocSelection = () => {
  const selection = document.getSelection()
  const focus = selection.focusNode.parentElement
  const anchor = selection.anchorNode.parentElement
  const position = selection.focusNode.compareDocumentPosition(selection.anchorNode)

  if (focus.classList.contains('token-cell')
      && anchor.classList.contains('token-cell')) {

    if (position == 2) {
      return JSON.stringify({ left: Math.floor(anchor.id),
                              right: Math.floor(focus.id),
                              first: Math.floor(anchor.id),
                              last: Math.floor(focus.id)
                            })
    } else if (position == 4) {
      return JSON.stringify({ right: Math.floor(anchor.id),
                              left: Math.floor(focus.id),
                              first: Math.floor(focus.id),
                              last: Math.floor(anchor.id)
                            })
    } else if (selection.focusNode == selection.anchorNode) {
      return JSON.stringify({ left: Math.floor(anchor.id),
                              right: Math.floor(focus.id),
                              first: Math.floor(focus.id),
                              last: Math.floor(focus.id)
                            })
    } else {
      return JSON.stringify(null)
    }
  } else {
      return JSON.stringify(null)
  }
}

class OrgNoteElement extends HTMLElement {
  constructor() { super() }
  connectedCallback() { this.setInnerHTML() }
  attributeChangedCallback() { this.setInnerHTML() }
  static get observedAttributes() { return ['note'] }

  setInnerHTML()
  {
    const note = this.getAttribute('note')
    this.innerHTML = renderOrg(note)
  }
}

customElements.define('org-note', OrgNoteElement)

const app = Elm.Main.init(
  { node: document.getElementById('root'),
    flags: { apiurl: process.env.API_URL,
             authurl: process.env.AUTH_URL,
             anon_apikey: process.env.ANON_APIKEY,
             authtoken: getItem('authtoken'),
             email: getItem('email')
           }
  });

if (app.ports && app.ports.sendToLocalStorage) {
  if (app.ports.sendToLocalStorage) {
    app.ports.sendToLocalStorage.subscribe(({ key, value }) => {
      window.localStorage[key] = JSON.stringify(value)
    })
  }

  if (app.ports.requestDocSelection && app.ports.receivedDocSelection) {
    app.ports.requestDocSelection.subscribe(() => {
      app.ports.receivedDocSelection.send(getDocSelection())
    })
  }
}
